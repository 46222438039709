import React from 'react';
import styled from 'styled-components';
import {Pagination, Input} from 'antd';
import qs from 'query-string';
import {PinAngleFill} from '@styled-icons/bootstrap/PinAngleFill';
import withPageEntry from '../../withPageEntry';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {formatDateStr} from '../../Utils/TimeUtil';

function formatReleaseDateStr(str) {
  return (str || '').slice(0, 10);
}

function AnnouncementList(props) {
  const params = qs.parse(props.location.search);
  const {type} = params;
  const [selectedTab, setSelectedTab] = React.useState(
    type ? (type === 'important' ? 0 : 1) : 0,
  );
  const [searchText, setSearchText] = React.useState('');
  const [searchImportantText, setSearchImportantText] = React.useState('');
  const [searchNewspaperText, setSearchNewspaperText] = React.useState('');
  const [important, setImportant] = React.useState(null);
  const [newspaper, setNewspaper] = React.useState(null);
  const [importantPagination, setImportantPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [newspaperPagination, setNewspaperPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchImportant = React.useCallback(
    async ({keyword, page, pageSize}) => {
      try {
        AppActions.setLoading(true);

        const query = {
          $or: [
            {is_open: '1', Type_Id: 'important', is_pin: true},
            {is_open: '1', Type_Id: 'important'},
          ],
        };

        if (!!keyword) {
          query.Title = {$regex: keyword};
        }

        let resp_important = await JStorage.fetchDocuments(
          'News',
          query,
          ['-is_pin', '-created'],
          {
            offset: (page - 1) * pageSize,
            limit: pageSize,
          },
        );

        setImportant(resp_important);
        setImportantPagination({page, pageSize, total: resp_important.total});

        if (!!keyword) {
          setSearchImportantText(`重大消息共 ${resp_important.total} 筆`);
        }
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    },
    [],
  );

  const fetchNewsPaper = React.useCallback(
    async ({keyword, page, pageSize}) => {
      try {
        AppActions.setLoading(true);

        const query = {
          $or: [
            {is_open: '1', Type_Id: 'news_paper', is_pin: true},
            {is_open: '1', Type_Id: 'news_paper'},
          ],
        };

        if (!!keyword) {
          query.Title = {$regex: keyword};
        }

        let resp_newspaper = await JStorage.fetchDocuments(
          'News',
          query,
          ['-is_pin', '-created'],
          {
            offset: (page - 1) * pageSize,
            limit: pageSize,
          },
        );

        setNewspaper(resp_newspaper);
        setNewspaperPagination({
          page,
          pageSize,
          total: resp_newspaper.total,
        });

        if (!!keyword) {
          setSearchNewspaperText(`新聞稿共 ${resp_newspaper.total} 筆`);
        }
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (!important) {
      fetchImportant(importantPagination);
    }

    if (!newspaper) {
      fetchNewsPaper(newspaperPagination);
    }
  }, [
    important,
    newspaper,
    importantPagination,
    newspaperPagination,
    fetchImportant,
    fetchNewsPaper,
  ]);

  return (
    <Wrapper selectedTab={selectedTab}>
      <div className="container">
        <h1>公告訊息</h1>

        <div className="search-container">
          <div className="search-input">
            <Input.Search
              allowClear
              placeholder="請輸入關鍵字搜尋"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);

                if (!e.target.value) {
                  setSearchNewspaperText('');
                  setSearchImportantText('');
                }
              }}
              onSearch={async (value) => {
                try {
                  AppActions.setLoading(true);

                  await fetchImportant({
                    keyword: value,
                    page: 1,
                    pageSize: 10,
                  });

                  await fetchNewsPaper({
                    keyword: value,
                    page: 1,
                    pageSize: 10,
                  });
                } catch (err) {
                  console.warn(err);
                } finally {
                  AppActions.setLoading(false);
                }
              }}
            />
          </div>
          {searchImportantText && searchNewspaperText && (
            <div className="search-result">
              搜尋結果：{searchImportantText}，{searchNewspaperText}。
            </div>
          )}
        </div>

        <div className="tab-header">
          <div className="item index-0" onClick={() => setSelectedTab(0)}>
            重大訊息
          </div>
          <div className="item index-1" onClick={() => setSelectedTab(1)}>
            新聞稿
          </div>
        </div>

        {selectedTab === 0 && (
          <div className="tab-container">
            {important?.results.map((item, index) => (
              <div
                key={`tab-0-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/announcement?id=${item.id}`)
                }>
                <div className="name">
                  {item.is_pin && (
                    <PinAngleFill
                      size={26}
                      color="#EA0000"
                      style={{paddingRight: 5}}
                    />
                  )}
                  {item.Title}
                </div>
                <div className="date">
                  {(item.Release_date &&
                    formatReleaseDateStr(item.Release_date)) ||
                    formatDateStr(new Date(item.created))}
                </div>
              </div>
            ))}
            <div className="page-container">
              <Pagination
                total={importantPagination.total}
                showTotal={(total) => `共 ${total} 筆`}
                onChange={(page, pageSize) => {
                  fetchImportant({page, pageSize});
                }}
              />
            </div>
          </div>
        )}
        {selectedTab === 1 && (
          <div className="tab-container">
            {newspaper?.results.map((item, index) => (
              <div
                key={`tab-1-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/announcement?id=${item.id}`)
                }>
                <div className="name">
                  {item.is_pin && (
                    <PinAngleFill
                      size={26}
                      color="#EA0000"
                      style={{paddingRight: 5}}
                    />
                  )}
                  {item.Title}
                </div>
                <div className="date">
                  {(item.Release_date &&
                    formatReleaseDateStr(item.Release_date)) ||
                    formatDateStr(new Date(item.created))}
                </div>
              </div>
            ))}
            <div className="page-container">
              <Pagination
                total={newspaperPagination.total}
                showTotal={(total) => `共 ${total} 筆`}
                onChange={(page, pageSize) => {
                  fetchNewsPaper({page, pageSize});
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  & > .container {
    margin: 0 auto;
    max-width: var(--containerMaxWidth900px);
    width: 100%;
    padding: 20px 40px;

    & > h1 {
      margin-bottom: 20px;
      text-align: center;
    }

    & > div.search-container {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & > .search-result {
        margin-top: 15px;
        font-size: 16px;
        margin-top: 15px;
      }

      & > .search-input {
        max-width: 500px;
        width: 100%;
      }
    }

    & > div.tab-header {
      display: flex;

      & > div.item {
        flex: 1;

        cursor: pointer;
        border-bottom: 5px solid #969696;
        padding: 5px;

        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }

      ${(props) =>
        props.selectedTab === 0
          ? `& > div.index-0 {  border-bottom: 5px solid var(--secondColor); }`
          : `& > div.index-1 {  border-bottom: 5px solid var(--secondColor); }`}
    }

    & > div.tab-container {
      margin-bottom: 30px;
      min-height: 245px;

      & > div.item {
        border-bottom: 3px solid #c8c8c8;
        padding: 12px 0px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        & > .name {
          flex: 1;
          margin-right: 10px;
          font-size: 1rem;
        }

        & > .date {
          width: 110px;
          font-size: 1rem;
        }

        &:hover {
          color: var(--primaryColor);
        }
      }

      & > div.page-container {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  @media screen and (max-width: 500px) {
    & > .container {
      padding: 20px 0px;
    }
  }
`;

export default withPageEntry(AnnouncementList);
